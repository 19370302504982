body {
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
  color: #333;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  padding: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #2c3e50;
  font-size: 3em;
  margin-bottom: 20px;
}

h2 {
  color: #3498db;
  font-size: 2em;
}

h3 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-article {
  max-width: 800px;
  margin-bottom: 40px;
  text-align: left;
  line-height: 1.6;
}

form {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  padding: 12px;
  font-size: 16px;
  width: 400px;
  border: 2px solid #3498db;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover:not(:disabled) {
  background-color: #2980b9;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.error {
  color: #e74c3c;
  margin-top: 10px;
}

.url-info, .ip-info {
  background-color: #ecf0f1;
  padding: 30px;
  border-radius: 8px;
  margin-top: 30px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.url-info h2, .ip-info h2 {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.url-info p, .ip-info p {
  margin: 10px 0;
}

.url-info ul {
  list-style-type: none;
  padding-left: 0;
}

.url-info li {
  margin: 5px 0;
}

@media (max-width: 600px) {
  input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  form {
    flex-direction: column;
  }

  button {
    width: 100%;
  }
}
